.ant-message .anticon {
  top: -3px !important;
}

.container-tableDoc {
  position: relative;
  .btn-back-container {
    position: absolute;
    top: 20px;
    left: -9%;
    @media screen and (max-width: 991px) {
      left: -4.6%;
    }
    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
    .btn-back-img {
      width: 25px;
      height: 25px;
      background-image: url("../../asset/img/scroll-top@3x.png");
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }
    .btn-back {
      color: rgb(173, 178, 186);
      font-size: 14px;
      font-weight: 700;
      margin-left: 5px;
    }
  }
  .details-user {
    background-color: white;
    border-radius: 4px;
    box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.12);
    border-left: 4px solid rgb(84, 94, 112);
    font-weight: normal;
    color: rgb(84, 94, 112);
    .name {
      color: #597dda;
      font-weight: 700;
      font-size: 24px;
      line-height: 22px;
    }
    .user-data,
    .contract-info,
    .document-info,
    .personal-code,
    .location-info {
      position: relative;
      font-size: 14px;
      padding-left: 4px;
      .container-info {
        line-height: 16px;
        margin-bottom: 4px;
        .title {
          font-weight: 600;
          span {
            font-weight: normal;
          }
        }
      }
    }
    .contract-info,
    .document-info,
    .personal-code,
    .location-info {
      border-top: 1px solid rgb(128, 127, 128);
      &::after {
        content: "CONTRACT INFO";
        position: absolute;
        padding: 0 5px;
        top: -7px;
        left: -10px;
        font-weight: 200;
        font-size: 10px;
        color: rgb(128, 127, 128);
        background-color: white;
      }
      .circle {
        width: 16px;
        height: 16px;
        border-radius: 100%;
        &.grey {
          border: 1px solid rgba(84, 94, 112, 0.24);
        }
        &.green {
          background-color: rgb(96, 186, 68);
          box-shadow: 0px 0px 6px rgba(97, 187, 69, 0.5);
        }
      }
      &.info {
        padding-top: 12px;
        margin-top: 12px;
      }
    }
    .personal-code {
      &::after {
        content: "PRESONAL CODE";
      }
    }
    .location-info {
      &::after {
        content: "LOCATION INFO";
      }
    }
    .document-info {
      &::after {
        content: "DOCUMENT INFO";
      }
    }
  }
  .title-cluster {
    font-size: 28px;
    font-weight: bold;
  }
  .search-cluster {
    border-radius: 4px;
    border: 1px solid rgb(231, 232, 233);
    height: 40px;
    .search-image,
    .remove-filter {
      height: 13px;
      width: 18px;
      background-image: url("../../asset/img/search_dropdown@3x.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
    .remove-filter {
      background-image: url("../../asset/img/esc.png");
      cursor: pointer;
    }
    input {
      height: 40px;
      border: none;
      background-color: transparent;
      color: #597dda;
      font-weight: 700;
      font-size: 12px;
      &::placeholder {
        font-size: 12px;
        font-weight: 700;
        color: #545e70;
      }
    }
    .container-searchField {
      position: absolute;
      top: 45px;
      left: 0;
      right: 0;
      background-color: white;
      border-radius: 4px;
      box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.12);
      padding: 16px 10px 0;
      z-index: 2;
      .search-field {
        font-size: 12px;
        color: #545e70;
        z-index: 3;
        cursor: pointer;
        p {
          margin-bottom: 8px;
          strong {
            text-transform: capitalize;
            &:hover {
              opacity: 0.9;
            }
          }
        }
      }
    }
  }
  .head-cluster {
    color: rgb(84, 94, 112);
    font-weight: bold;
    font-size: 18px;
    padding-bottom: 10px;
    .void-elm {
      max-width: 50px;
    }
  }
  .container-clusters {
    height: 435px;
    overflow-y: scroll;
    @media screen and(max-width:768px) {
      height: unset;
      overflow-y: unset;
    }
    &::-webkit-scrollbar {
      width: 7px;
      height: 100%;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 5rem;
      background: rgba(84, 94, 112, 0.12) !important;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: rgba(84, 94, 112, 0.3) !important;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent !important;
      margin-right: 15px;
    }
    .empty-filter {
      height: 100%;
      padding: 12px;
      div {
        color: rgba(84, 94, 112, 0.6);
      }
      span {
        color: #597dda;
        cursor: pointer;
      }
      .no-result {
        width: 100%;
        height: 75px;
        background-image: url("../../asset/img/no-results-icon@3x.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
  }
}
