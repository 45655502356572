.react-pdf__message {
  &.react-pdf__message--loading,
  &.react-pdf__message--error {
    text-align: center;
  }
}
.modal {
  position: fixed;
  background-color: white;
  position: fixed;
  height: unset !important;
  top: 91px !important;
  bottom: 0;
  right: 0 !important;
  left: auto !important;
  padding-top: 20px;
  color: rgb(59, 65, 73);
  overflow-y: scroll !important;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
  &.fade {
    right: -100%;
    -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
    -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
    -o-transition: opacity 0.3s linear, right 0.3s ease-out;
    transition: opacity 0.3s linear, right 0.3s ease-out;
    .modal-dialog {
      transition: width 2.3s ease-in-out !important;
    }
  }
  &.show {
    right: 0;
    bottom: 0;
    .modal-dialog {
      transform: none;
    }
  }
  .modal-dialog {
    padding: 0 9px;
    margin: 0 !important;
    @media screen and (min-width: 576px) {
      max-width: 100% !important;
      margin: 0 !important;
    }
    @media screen and (max-width: 891.98px) {
      max-width: 100%;
    }
    .modal-content {
      border-radius: 0;
      border: none;
      .container-esc {
        font-size: 14px;
        font-style: italic;
        color: rgba(84, 94, 112, 50%);
        margin-right: 10px;
        .btn-close {
          width: 20px;
          height: 20px;
          background-image: url("../../asset/img/esc@3x.png");
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          cursor: pointer;
          &:focus {
            outline: none !important;
          }
          .btn {
            &:focus {
              outline: none;
              box-shadow: none;
            }
          }
        }
      }
      .container-pdf {
        @media screen and (min-width: 576px) {
          height: 90%;
        }
        @media screen and (max-width: 891.98px) {
          height: 60%;
        }
        .background-pdf {
          .pdf {
            background-color: rgba(59, 65, 73, 0.1);
          }
        }
      }
      .container-history {
        .container-modal-header {
          margin-top: 24px;
          margin-bottom: 12px;
          .title {
            font-size: 42px;
            line-height: 30px;
            font-weight: 700;
            margin: 0;
          }
          .owner {
            font-size: 14px;
            color: #577dda;
            margin: 0;
            padding-left: 15px;
          }
        }
        .version {
          &:hover {
            background-color: rgba(89, 125, 218, 0.05) !important;
            cursor: pointer;
          }
        }
      }
    }
  }
}

/* Safari 4.0 - 8.0 */
@-webkit-keyframes example {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Standard syntax */
@keyframes example {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
