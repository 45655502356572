.container-cluster {
  font-weight: bold;
  color: rgb(84, 94, 112);
  &:last-child {
    .file-container {
      margin-bottom: 0 !important;
    }
  }
  .cluster {
    position: relative;
    background-color: rgba(84, 94, 112, 0.24);
    border-radius: 4px;
    &:hover {
      cursor: pointer;
      background-color: rgba(84, 94, 112, 0.34);
    }
    .number-check {
      max-width: 50px !important;
      border-right: 1px solid rgb(189, 192, 198);
      .check {
        width: 16px;
        height: 16px;
        background-image: url("../../asset/img/spunta-grey@3x.png");
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        border-radius: 100%;
      }
    }

    .show,
    .hide {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      font-style: italic;
      font-weight: 300;
      font-size: 14px;
      .show-icon,
      .hide-icon {
        width: 18px;
        height: 18px;
        background-image: url("../../asset/img/ShowMore@3x.png");
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        margin-left: 6px;
      }
      .hide-icon {
        transform: rotate(180deg);
      }
    }
  }
  .file-container {
    .cluster-open {
      position: relative;
      border-bottom: 1px solid rgba(84, 94, 112, 0.25);
      border-left: 2px solid transparent;
      &.no-present {
        color: rgba(84, 94, 112, 0.72);
      }
      &.hover {
        border-left: 2px solid #597dda;
        background-color: rgba(89, 125, 218, 0.1);
        .btn-view,
        .btn-upload {
          display: block;
          color: rgb(125, 151, 210);
          &:hover {
            cursor: pointer;
            opacity: 0.9;
          }
        }
        .btn-upload {
          display: block;
        }
        .upload {
          label {
            display: block;
            color: rgb(100, 200, 132);
            font-weight: 700;
            font-size: 16px;
            margin: 0;
          }
        }
        .btn-upload {
          display: none;
          font-size: 0;
          width: 0;
          height: 0;
          &::-webkit-file-upload-button {
            &:focus {
              outline: none;
              border: none;
            }
          }
        }
        .file-menu {
          display: block !important;
          &:hover {
            cursor: pointer;
            opacity: 0.9;
          }
        }
      }
      .number-check {
        max-width: 48px !important;
        .update {
          width: 16px;
          height: 16px;
          background-image: url("../../asset/img/spunta-green@3x.png");
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          border-radius: 100%;
          &.hover {
            background-image: url("../../asset/img/spunta_hover@3x.png");
          }
        }
        .void {
          width: 16px;
          height: 16px;
          border: 1px solid rgba(84, 94, 112, 0.24);
          border-radius: 100%;
        }
      }
      .btn-view,
      .btn-upload {
        display: none;
      }
      .upload {
        label {
          display: none;
        }
      }
      .file-menu {
        display: none;
        width: 24px;
        height: 24px;
        background-image: url("../../asset/img/menu@3x.png");
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        border-radius: 100%;
        &.active {
          background-image: url("../../asset/img/menu_hover@3x.png");
        }
      }
      .doc-menu {
        position: absolute;
        top: calc(100% + 2px);
        left: -20px;
        right: 0;
        font-size: 14px;
        background-color: white;
        z-index: 2;
        box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.12);
        border-radius: 8px;
        > div {
          &:first-child {
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
          }
          &:last-child {
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
          }
          &:hover {
            background-color: rgba(89, 125, 218, 0.1);
            cursor: pointer;
          }
        }
        .confirm,
        .cancel {
          width: 14px;
          height: 14px;
          background-image: url("../../asset/img/done@3x.png");
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          cursor: pointer;
        }
        .cancel {
          background-image: url("../../asset/img/delete@3x.png");
        }
      }
    }
  }
}
