.filterCheckbox {
  padding: 5px 0 0 3px;
  &:hover {
    opacity: 0.8;
    cursor: pointer;
    .title-close {
      font-weight: bold;
    }
  }
  .container-title-close,
  .container-title-open {
    padding: 4px 8px;
    border-radius: 4px;
    background-color: rgb(242, 242, 242);
  }

  .container-title-open {
    background-color: transparent;
  }
  .btn-clear-filter {
    font-weight: bold;
    font-size: 8px !important;
    color: red;
  }
  .add-filter {
    opacity: 0.8;
    font-weight: bold;
    font-size: 8px;
  }
  .title-open,
  .title-close {
    font-size: 14px;
    font-weight: 400;
    display: inline-block;
    margin: 0px;
    position: relative;
    .after {
      &::after {
        content: "•";
        width: 7px;
        height: 7px;
        position: absolute;
        top: -7px;
        right: -9px;
        color: orange;
      }
    }
  }
  .title-open {
    color: #3b4149;
    font-weight: bold;
    font-size: 14px;
  }

  .container-list {
    .search {
      margin: 5px 8px 0;
      border-radius: 4px;
      border: none;
      background-color: #f2f2f2;
      .search-image {
        height: 12px;
        width: 12px;
        background-image: url("../../asset/img/search_dropdown@3x.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
      }
      input {
        height: 33px;
        border: none;
        &::placeholder {
          font-size: 12px;
          font-weight: 200;
          color: rgba(59, 65, 74, 0.5);
        }
      }
    }
    .filter-company {
      margin-top: 5px;
      padding: 0 18px;
      height: 85px;
      overflow-y: scroll;
      border-bottom: 1px solid rgba(84, 94, 112, 0.5);
      &::-webkit-scrollbar {
        height: 100%;
        width: 7px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 5rem;
        background: rgba(84, 94, 112, 0.12) !important;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: rgba(84, 94, 112, 0.3) !important;
      }
      &::-webkit-scrollbar-track {
        background-color: transparent !important;
      }
      input {
        height: 20px;
      }
      .row {
        margin-bottom: 6px;
      }
      span {
        padding-left: 8px;
        font-size: 14px;
        color: #3b4149;
        opacity: 0.72;
      }
    }
  }
}
