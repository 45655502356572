a {
  color: rgb(59, 65, 73);
  &:hover {
    text-decoration: none;
  }
}
.user-status {
  padding-bottom: 10px;
}
button:focus,
input:focus {
  outline: none !important;
  box-shadow: none !important;
}
.filter {
  &.search {
    margin: 15px 10px 15px 7px !important;
    border: 1px solid rgba(59, 65, 73, 0.12);
    border-radius: 4px;
    position: relative;
    .search-image {
      height: 13px;
      width: 18px;
      background-image: url("../../asset/img/search_dropdown@3x.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
    input {
      height: 40px;
      border: none;
      background-color: transparent;
      color: #597dda;
      font-weight: 700;
      font-size: 12px;
      &::placeholder {
        font-size: 12px;
        font-weight: 700;
        color: #545e70;
      }
    }
    .filter {
      border-left: 1px solid rgb(231, 232, 233);
      .filter-image {
        height: 25px;
        width: 25px;
        background-image: url("../../asset/img/icon-dropdown@3x.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
      }
    }
    .container-searchField {
      position: absolute;
      top: 50px;
      left: 0;
      right: 0;
      background-color: white;
      border-radius: 4px;
      box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.12);
      padding: 16px 10px 0;
      z-index: 2;
      .search-field {
        font-size: 12px;
        color: #545e70;
        p {
          margin-bottom: 8px;
          strong {
            text-transform: capitalize;
            &:hover {
              cursor: pointer;
              opacity: 0.9;
            }
          }
        }
      }
    }

    .dropdown-filter {
      position: absolute;
      top: 50px;
      left: 0px;
      right: -350px;
      background-color: white;
      border-radius: 4px;
      box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.12);
      padding: 0px 20px 15px 24px;
      z-index: 3;
      @media screen and(max-width:620px) {
        right: -310px;
      }
      @media screen and(max-width:576px) {
        right: -130px;
      }
      .title {
        font-size: 17px;
        font-weight: 700;
        color: #597dda;
        margin-bottom: 16px;
        margin-top: 16px;
      }
      .column-filter {
        padding-bottom: 11px;
        border-bottom: 1px solid rgba(84, 94, 112, 0.5);
        .sorting {
          .sort {
            background-color: #f2f2f2;
            font-size: 10px;
            border-radius: 4px;
            padding: 3px 5px;
            .opacity {
              opacity: 0.4;
            }
            .arrow-left,
            .arrow-right {
              background-image: url("./../../asset/img/arrow-grey@3x.png");
              background-repeat: no-repeat;
              background-position: center;
              background-size: contain;
              transform: rotate(180deg);
              cursor: pointer;
            }
            .arrow-right {
              transform: rotate(360deg);
            }
            .text-center {
              cursor: default;
            }
          }
        }
        .sort-container {
          margin-left: 16px;
          padding-right: 15px;
          padding-bottom: 12px;
          .row-container {
            padding-bottom: 8px;
            span {
              font-size: 12px;
              color: #3b4149;
            }
          }
          &:last-child {
            padding-bottom: 0;
          }
          .sort-icon {
            width: 20px;
            height: 20px;
            background-image: url("../../asset/img/sort.png");
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
          }
          .toogle-switch {
            height: 11px;
            width: 34px;
            position: relative;
            border-radius: 10px;
            &:hover {
              cursor: pointer;
            }
            &::after {
              content: "";
              width: 18px;
              height: 18px;
              position: absolute;
              top: -3px;
              right: unset;
              border-radius: 18px;
            }
            &.off {
              background-color: rgb(231, 231, 231);
              &::after {
                left: 0;
                background-color: rgb(153, 153, 153);
                -webkit-transition: all 0.5s ease;
                -moz-transition: all 0.5s ease;
                -o-transition: all 0.5s ease;
                -ms-transition: all 0.5s ease;
                transition: all 0.5s ease;
              }
            }
            &.on {
              background-color: rgb(215, 224, 246);
              &::after {
                left: calc(100% - 18px);
                background-color: #597dda;
                -webkit-transition: all 0.5s ease;
                -moz-transition: all 0.5s ease;
                -o-transition: all 0.5s ease;
                -ms-transition: all 0.5s ease;
                transition: all 0.5s ease;
              }
            }
          }
        }
      }

      .btn {
        font-size: 14px;
        margin-top: 14px;
        &.btn-reset {
          color: #597dda;
          margin-right: 12px;
          &:hover {
            background-color: #597dda;
            color: white;
          }
        }
        &.btn-apply {
          font-weight: 700;
          background-color: #597dda;

          color: white;
          padding: 6px 28px;
          &:hover {
            background-color: #3d61bb;
            color: white;
          }
        }
      }
    }
  }
}
