#tab-doc {
  scroll-behavior: smooth;
}

html {
  scroll-behavior: smooth;
}

body {
  width: 100%;
}

a {
  color: rgb(59, 65, 73);
  &:hover {
    text-decoration: none;
  }
}

button:focus {
  outline: none !important;
  box-shadow: none !important;
}

.hover {
  background-color: rgba(89, 125, 218, 0.1);
}

.container-table {
  margin-top: 75px;
  .user-head {
    font-weight: 700;
    font-size: 34px;
    .btn-export {
      background-color: rgb(89, 125, 218);
      opacity: 0.7;
      border-radius: 4px;
      font-size: 20px;
      padding: 10px 35px;
      margin: 10px 0;
      font-weight: bold;
      color: white;

      &:hover {
        opacity: 1;
        color: white;
      }
      .download {
        width: 20px;
        height: 20px;
        margin-left: 6px;
        background-image: url("../../asset/img/exportCsv@3x.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
      }
    }
  }
  .filter-row {
    .filter-view {
      padding: 5px 15px;
      margin: 0 5px 5px;
      background-color: orange;
      color: white;
      border-radius: 20px;
      z-index: 2;
      span {
        font-weight: 700;
      }
      .close-filter {
        width: 18px;
        height: 18px;
        background-image: url("../../asset/img/close@3x.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        cursor: pointer;
        margin-left: 12px;
      }
    }
  }
  .box-table {
    width: 100%;
    position: relative;
    .shadow-1 {
      width: 30px;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      z-index: 1;
      background-image: linear-gradient(
        to right,
        rgba(255, 0, 0, 0),
        rgb(255, 255, 255)
      );
    }
    .container-tableAll {
      height: 100%;
      position: relative;
      // padding-bottom: 60px;
      box-sizing: content-box;
      .table {
        position: relative;
        .user-container {
          min-height: 130px;
          min-width: 263px;
          position: absolute;
          top: 27px;
          bottom: 0;
          background-color: white;
          border-left: 4px solid #545e70;
          border-radius: 4px;
          box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.12);
          z-index: 1;
          max-width: 260px;
        }
        .container-user-cell {
          position: relative;
          color: rgb(84, 94, 112);
          font-size: 14px;
          font-weight: 200;
          padding: 12px;
          .info {
            width: 12px;
            height: 12px;
            margin-right: 5px;
            background-image: url("./../../asset/img/info@3x.png");
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            &:hover {
              .info-hover-open {
                display: block;
              }
            }
            .info-hover-open {
              display: none;
              padding: 15px;
              position: absolute;
              top: 0;
              right: 0;
              left: 0;
              background-color: white;
              z-index: 3;
              box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.12);
              border-radius: 4px;
            }
          }
          .name {
            font-weight: bold;
            font-size: 18px;
            word-break: break-word;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            &.fullName {
              font-weight: bold;
              font-size: 18px;
              word-break: break-word;
              white-space: unset;
              overflow: unset;
              text-overflow: unset;
            }
          }
          .company {
            color: #597dda;
            font-size: 14px;
            font-weight: 500;
            line-height: 11px;
            margin-bottom: 6px;
          }
          .title {
            font-weight: 600;
            line-height: 17px;
            span {
              font-weight: 400;
            }
          }
          .more-info,
          .info-location {
            position: relative;
            border-top: 1px solid rgb(128, 127, 128);
            padding-top: 12px;
            margin-top: 12px;
            &::after {
              content: "MORE INFO";
              position: absolute;
              padding: 0 5px;
              top: -7px;
              left: 0;
              font-weight: 200;
              font-size: 10px;
              color: rgb(128, 127, 128);
              background-color: white;
            }
          }
          .info-location {
            &::after {
              content: "LOCATION";
            }
          }
          .inactive {
            position: relative;
            opacity: 0.5;
            .name {
              position: relative;
              &::after {
                content: "•";
                width: 7px;
                height: 7px;
                position: absolute;
                // right: -10px;
                top: -8px;
                color: red;
              }
            }
          }
        }
        .empty-filter {
          padding: 12px;
          div {
            color: rgba(84, 94, 112, 0.6);
            line-height: 18px;
          }
          span {
            color: #597dda;
            cursor: pointer;
          }
          .no-result {
            width: 100%;
            height: 75px;
            background-image: url("../../asset/img/no-results-icon@3x.png");
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
          }
        }
      }
      .doc-container {
        position: relative;
        overflow-x: scroll;
        &::-webkit-scrollbar {
          width: 100%;
          height: 7px;
        }
        @media screen and (max-width: 576px) {
          margin-left: 217px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 5rem;
          background: rgba(84, 94, 112, 0.12) !important;
        }
        &::-webkit-scrollbar-thumb:hover {
          background: rgba(84, 94, 112, 0.3) !important;
        }
        &::-webkit-scrollbar-track {
          background-color: transparent !important;
          margin-right: 15px;
        }

        .doc-head {
          flex-wrap: nowrap;
          font-weight: bold;
          height: 100px;

          .sorting-selected {
            min-width: 250px;
            border-bottom: 1px solid rgb(84, 94, 112);
            padding-bottom: 7px;
            .bg-sorting {
              padding: 4px 8px;
              background-color: rgb(205, 207, 212);
              border-radius: 4px;
              cursor: pointer;
            }
            .asc,
            .dec {
              width: 16px;
              height: 24px;
              background-image: url("../../asset/img/hire_data@3x.png");
              background-position: center;
              background-repeat: no-repeat;
              background-size: 60%;
              margin-left: 8px;
            }
            .dec {
              transform: rotate(180deg);
            }
          }
          .doc {
            border-bottom: 1px solid rgb(84, 94, 112);
            width: 19.61%;
            min-width: 130px;
            text-align: center;
            color: #545e70;
            word-break: break-word;
            padding: 0 10px 10px;
          }
        }
        .empty-table {
          height: 160px;
        }
        .doc-row {
          flex-wrap: nowrap;
          .doc-check {
            width: 19.61%;
            min-width: 130px;
            border-bottom: 1px solid rgba(84, 94, 112, 0.5);
            &.hire {
              min-width: 250px;
              color: #545e70;
            }
            .circle {
              width: 16px;
              height: 16px;
              border-radius: 100%;
              &.grey {
                border: 1px solid rgba(84, 94, 112, 0.24);
              }
              &.green {
                background-color: rgb(96, 186, 68);
                box-shadow: 0px 0px 6px rgba(97, 187, 69, 0.5);
              }
            }
          }
        }
      }
      .page-container {
        width: 100%;
        height: 30px;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .prev,
        .next {
          width: 30px;
          height: 100%;
          border-top-left-radius: 35px;
          border-bottom-left-radius: 35px;
          background: rgb(234, 235, 238);
          background-image: url("../../asset/img/left@3x.png");
          background-size: 45%;
          background-position: center;
          background-repeat: no-repeat;
          &:hover {
            cursor: pointer;
            opacity: 0.9;
          }
        }
        .next {
          background-image: url("../../asset/img/right@3x.png");
          border-radius: 0;
          border-top-right-radius: 35px;
          border-bottom-right-radius: 35px;
        }
        .page {
          width: 80px;
          background: rgb(205, 207, 212);
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          margin: 5px;
          border-radius: 3px;
        }
      }
    }
  }
}
