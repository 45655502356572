header {
  width: 100%;
  height: 92px;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: white;
  z-index: 1000;
  box-shadow: 0px 8px 12px rgba(73, 85, 102, 0.122);
  background-color: white;
  .logo {
    height: 100%;
    background-image: url("../../asset/img/logo_fed_1@3x.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center left;
  }
  .container-user {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    .name-user {
      margin-right: 10px !important;
      white-space: nowrap;
      color: rgb(59, 65, 73);
      font-size: 16px;
      font-weight: bold;
    }
    .nameLetter {
      width: 40px;
      height: 40px;
      border-radius: 100%;
      background-color: rgb(89, 125, 218);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 22px;
      color: white;
    }
  }
}
