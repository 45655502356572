html,
body,
#root,
.app,
.container,
.container-page {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll;
  overflow-x: hidden;
}

input::-ms-clear {
  display: none;
}

.containerSpinner,
.container-error {
  height: calc(100% - 73px);
}

.container-page {
  padding-top: 110px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.footer {
  border-top: 1px solid rgba(84, 94, 112, 50%);
  position: relative;
  bottom: 0;
}

.text-no-wrap {
  word-break: break-word;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.no-user-found {
  color: rgba(84, 94, 112, 0.6);
  line-height: 18px;
  margin-top: -17px;
}

.no-result {
  width: 100%;
  height: 75px;
  background-image: url("./asset/img/no-results-icon@3x.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
